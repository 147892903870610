<template>
    <div class="tw-h-full tw-p-1">
        <div class="tw-h-full">
            <div
                class="tw-justify-center tw-pb-[8px] tw-flex tw-w-full tw-h-full tw-items-center"
            >
                <SmallProductCard v-bind="related" />
            </div>
        </div>
    </div>
</template>

<script>
import SmallProductCard from "@catalogue/molecules/SmallProductCard.vue";

export default {
    components: {SmallProductCard},
    props: {
        related: {
            type: Object,
            required: true
        }
    }
};
</script>
