<template>
    <div
        class="tw-relative tw-p-2 tw-flex tw-flex-col tw-border-[1px] tw-shadow-sm tw-border-chards-silver-white tw-rounded-md tw-h-full tw-w-full"
    >
        <div
            class="tw-w-full tw-pt-3 tw-aspect-square tw-flex tw-justify-center tw-min-h-[170px] tw-h-[170px]"
        >
            <div class="tw-relative tw-overflow-hidden">
                <AppImage
                    loading="lazy"
                    :data-src="image_url"
                    :width="image.width"
                    :height="image.height"
                    :alt="alternativeText"
                    class="lazyload tw-h-full tw-object-center tw-object-contain lg:tw-h-full"
                    :src="image_url"
                />
                <div
                    v-if="overlay && overlay.text"
                    class="tw-absolute tw-right-0 tw-top-0 tw-h-16 tw-w-16"
                >
                    <div
                        class="tw-absolute tw-transform tw-rotate-45 tw-bg-red-700 tw-text-center tw-text-white tw-font-semibold tw-uppercase tw-text-[0.5rem] tw-py-0.5 tw-right-[-22px] tw-top-[20px] tw-w-[105px] md:tw-right-[-28px] md:tw-top-[28px] md:tw-w-[140px] md:tw-py-1 md:tw-text-xxs"
                    >
                        {{ overlay.text }}
                    </div>
                </div>
            </div>
        </div>
        <div class="tw-flex tw-h-full tw-px-3">
            <ExperimentalStack
                element="div"
                :direction-experimental="{ mobile: 'vertical' }"
                :gap="{ mobile: '', tablet: '', desktop: '' }"
            >
                <AppLink
                    :href="url"
                    family="serif"
                    tone="chards-dark"
                    classes="tw-mt-4 tw-leading-6 hover:tw-underline sm:tw-text-sm lg:tw-text-base"
                >
                    <span
                        aria-hidden="true"
                        class="tw-absolute tw-inset-0"
                    />
                    {{ display_name }}
                </AppLink>
                <AppText
                    v-if="variants"
                    size="small"
                    tone="passive"
                >
                    {{ variants.join(', ') }}
                </AppText>
                <AppPrice
                    :classes="`tw-mt-auto tw-text-chards-purple sm:tw-text-xs tw-pt-2${true || (!price.premium && !price.surcharge_per_oz) ? ' tw-pb-1' : ''}`"
                    :price="price"
                    :simple-price="true"
                    size="small"
                />
            </ExperimentalStack>
        </div>
    </div>
</template>

<script>
import AppText from '@app/atoms/AppText.vue';
import AppLink from '@app/atoms/AppLink.vue';
import AppPrice from '@app/atoms/AppPrice.vue';
import ExperimentalStack from '@containers/ExperimentalStack.vue';
import AppImage from "@app/atoms/AppImage.vue";

export default {
    name: 'SmallProductCard',
    components: {
        AppImage,
        AppText,
        AppLink,
        AppPrice,
        ExperimentalStack
    },
    props: {
        url: {
            type: String,
            required: true
        },
        /* eslint-disable vue/prop-name-casing */
        image_url: {
            type: [String, null],
            required: true
        },
        display_name: {
            type: String,
            required: true
        },
        stock_status: {
            type: String,
            required: true
        },
        overlay: {
            type: Object,
            default: () => {
            }
        },
        /* eslint-enable vue/prop-name-casing */
        variants: {
            type: Array,
            default: () => []
        },
        price: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            alternativeText: this.display_name,
            image: {
                width: 159,
                height: 159,
                src: ''
            }
        };
    },
    mounted() {
        let image = new Image();
        image.src = this.image_url;
        image.onload = () => {
            this.image.width = 159;
            this.image.height = 159;
        };
    }
};
</script>
