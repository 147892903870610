<template>
    <AppCarousel
        identifier="related"
        :title="title"
        :items="related"
        :separator="seperator"
        :fade="fade"
        :breakpoints="{
            320: { slidesPerView: 2, spaceBetween: 14, centeredSlides: false },
            768: { slidesPerView: 3, spaceBetween: 14, centeredSlides: false },
            1200: { slidesPerView: 4, spaceBetween: 14, centeredSlides: false },
            1400: { slidesPerView: 5, spaceBetween: 14, centeredSlides: false }
        }"
        :arrows="arrows"
        :infinite="infinite"
        :full-width="false"
        swiper-classes="sm:!tw-px-12 lg:!tw-px-0"
        client:visible
    >
        <template #belowTitle>
            <slot />
        </template>
        <template #default="{ item }">
            <RelatedProduct
                :related="item"
                aria-label="Popular Product"
            />
        </template>
    </AppCarousel>
</template>

<script>
import AppCarousel from "@app/molecules/AppCarousel.vue";
import RelatedProduct from "@product/molecules/RelatedProduct.vue";

export default {
    components: {RelatedProduct, AppCarousel},
    props: {
        related: {
            type: Array,
            required: true
        },
        seperator: {
            type: Boolean,
            required: false,
            default: false
        },
        fullWidth: {
            type: Boolean,
            required: false,
            default: false
        },
        fade: {
            type: Boolean,
            required: false,
            default: false
        },
        mobileWidth: {
            type: String,
            required: false,
            default: ''
        },
        arrows: {
            type: Object,
            required: false,
            default: () => ({mobile: true, tablet: true, desktop: true})
        },
        infinite: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>
